import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class MatchHistoryDataService extends APIBaseFunctions {
  private cachedMatchHistoryDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = MatchHistoryDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMatchHistoryDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/kamphistoriks?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/kamphistoriks?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = MatchHistoryDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMatchHistoryDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/kamphistoriks?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/kamphistoriks?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/kamphistoriks/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/kamphistoriks/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/kamphistoriks', data)
  }

  update (id: string, data: any) {
    return http.put(`/kamphistoriks/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/kamphistoriks/${id}`)
  }

  deleteAll () {
    return http.delete('/kamphistoriks')
  }
}

export default new MatchHistoryDataService()
