import http from '../http-common'

class MatchPlannerDataService {
  get (id: string, sid: number | null = null, mpd: number | null = null, smpd: boolean | null = null, rr: number | null = null, mld: boolean | null = null, tb: number | null, ct: number | null, extraParameter = '') {
    let mainParameters = ''

    if ((sid != null && Number(sid) > 0) || (mpd !== null && Number(mpd) >= 1) || smpd !== null || (rr !== null && Number(rr) >= 1) || mld !== null || extraParameter.length >= 1) {
      mainParameters = '/?'

      if (sid != null && Number(sid) > 0) {
        mainParameters += (mainParameters.length > 2 ? '&sid=' : 'sid=') + Number(sid).toString()
      }
      // if (mpd !== null && Number(mpd) >= 1) {
      //   // Deprecated.
      //   mainParameters += (mainParameters.length > 2 ? '&mpd=' : 'mpd=') + Number(mpd).toString()
      // }
      // if (smpd !== null) {
      //   // Deprecated.
      //   mainParameters += (mainParameters.length > 2 ? '&smpd=' : 'smpd=') + (smpd ? 1 : 0).toString()
      // }
      if (rr !== null && Number(rr) >= 1) {
        mainParameters += (mainParameters.length > 2 ? '&rr=' : 'rr=') + Number(rr).toString()
      }
      if (mld !== null) {
        mainParameters += (mainParameters.length > 2 ? '&mld=' : 'mld=') + (mld ? 1 : 0).toString()
      }
      if (tb !== null && Number(tb) >= 0) {
        mainParameters += (mainParameters.length > 2 ? '&tb=' : 'tb=') + Number(tb).toString()
      }
      if (ct !== null && Number(ct) >= 2) {
        mainParameters += (mainParameters.length > 2 ? '&ct=' : 'ct=') + Number(ct).toString()
      }
      if (extraParameter.length >= 1) {
        mainParameters += (mainParameters.length > 2 ? '&' : '') + extraParameter
      }
    }

    return http.get(`/match-planner/${id}${mainParameters}`)
  }

  create (data: any) {
    return http.post('/match-planner', data)
  }

  update (id: string, data: any) {
    return http.put(`/match-planner/${id}`, data)
  }
}

export default new MatchPlannerDataService()
