import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class SeasonsDataService extends APIBaseFunctions {
  private cachedSeasonDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = SeasonsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedSeasonDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/saesons?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/saesons?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/saesons/${id}`)
  }

  async asyncGet (id: string) {
    return http.get(`/saesons/${id}`)
  }

  getCount () {
    return http.get('/saesons/count')
  }

  create (data: any) {
    return http.post('/saesons', data)
  }

  update (id: string, data: any) {
    return http.put(`/saesons/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/saesons/${id}`)
  }

  deleteAll () {
    return http.delete('/saesons')
  }

  findPreviousSeason (status: boolean, ignoreStatus: boolean, seasonEndDate = '1970-01-01', tournamentCategoryId: number, extraParameter = '_limit=25') {
    let retVal
    const sortingOrderParameter = '&_sort=saeson_slut:desc'

    if (ignoreStatus) {
      // retVal = http.get(`/saesons?_where[_or][0][saeson_slut_lte]=${seasonEndDate.trim()}&_where[_or][1][raekke_id.turneringskategori_id_in]=${tournamentCategoryId}${sortingOrderParameter}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      retVal = http.get(`/saesons?saeson_slut_lte=${seasonEndDate.trim()}&raekke_id.turneringskategori_id_in=${tournamentCategoryId}${sortingOrderParameter}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    } else {
      // retVal = http.get(`/saesons?saeson_status=${status}&_where[_or][0][saeson_slut_lte]=${seasonEndDate.trim()}&_where[_or][1][raekke_id.turneringskategori_id_in]=${tournamentCategoryId}${sortingOrderParameter}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      retVal = http.get(`/saesons?saeson_status=${status}&saeson_slut_lte=${seasonEndDate.trim()}&raekke_id.turneringskategori_id_in=${tournamentCategoryId}${sortingOrderParameter}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return retVal
  }
}

export default new SeasonsDataService()
